$primary-color: #002d64;
$card-bg-color: #f5f5f5;
.bertelsmann-theme {
    div,
    p,
    input,
    select,
    option,
    label,
    ul,
    ol,
    li,
    #sitemap ul ul a {
        font-family: Arial, Helvetica, Verdana, sans-serif !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .table th {
        color: $primary-color;
    }
    background-color: white;
    asset-owner--assets,
    asset-owner-dashboard .container,
    asset-owner-dashboard {
        background: #fff;
    }
    // Styles for iFrame
    deloitteai-header,
    deloitteai-footer,
    deloitteai-page-banner,
    deloitteai-contact-banner {
        display: none !important;
    }
    .aihub-tooltip mat-icon,
    .badge.success,
    a,
    .available-items .mat-chip,
    .characters-left,
    .ngx-file-drop__content {
        color: $primary-color !important;
    }
    .deloitteai--header__list .counter {
        font-weight: bold;
    }
    .aihub-select--textfield input[type="text"]:focus,
    .listitem.focus,
    .ai-hub-form input[type="radio"]+span:before {
        border-color: $primary-color !important;
    }
    .listitem.focus {
        color: $primary-color !important;
    }
    .aihub-select--textfield input[type="text"]:focus {
        box-shadow: none !important;
    }
    .ai-hub-form input[type="number"]:focus,
    .ai-hub-form input[type="text"]:focus {
        border-color: $primary-color !important;
        box-shadow: 0 5px 5px 0 #e5eaef !important;
    }
    .ai-hub-form input[type=radio]:checked+span:after {
        background-color: $primary-color !important;
    }
    deloitteai-page {
        .page-body {
            asset-owner--barrier-questions-main {
                .main.container {
                    aihub-stepper {
                        asset-owner--technical-stack-questions {
                            .ai-hub-form {
                                .ai-hub-form {
                                    &--control {
                                        label {
                                            display: inline-block;
                                            flex-direction: unset;
                                        }
                                    }
                                }
                            }
                        }
                        .ai-hub-form input[type="radio"]+span:before {
                            border: 1px solid $primary-color;
                        }
                        .ai-hub-form input[type="radio"]+span:after {
                            border: 1px solid transparent;
                        }
                        .ai-hub-form input[type="checkbox"] {
                            accent-color: $primary-color;
                        }
                        .ai-hub-form input[type="radio"]:checked+span:after {
                            background-color: $primary-color;
                        }
                    }
                }
                aihub-breadcrumb {
                    display: none;
                }
                aihub-stepper-progress {
                    display: none;
                }
            }
            .asset-card {
                background: $card-bg-color;
                box-shadow: none;
                padding-bottom: 2.5rem;
                .asset-card--header {
                    color: $primary-color;
                    margin-bottom: 1.5rem !important;
                }
                .asset-card--body {
                    dl dt {
                        font-size: 14px;
                        color: #656565;
                        font-weight: 400;
                        margin-bottom: 0.25rem;
                    }
                }
                .asset-card--footer {
                    position: relative !important;
                    margin-top: 1.5rem;
                    .btn.btn-secondary {
                        background: #002d64 !important;
                        color: #ffffff;
                        height: 44px;
                        width: auto !important;
                        border-radius: 3px;
                        margin-right: 1rem;
                        padding: 0 3rem !important;
                    }
                }
                .badge.success {
                    color: #587312;
                    background: #cbdba3 !important;
                    border: 0;
                }
                .badge.danger {
                    color: #772727;
                    background: #ef8686 !important;
                    border: 0;
                }
            }
            asset-owner--menu li .label:before {
                background-color: $primary-color !important;
            }
        }
    }
    app-root {
        deloitteai-page {
            deloitteai-header {
                header.shared-header {
                    .navbar {
                        background-color: #f0f0f0;
                        .nav-item:hover {
                            background-color: transparent;
                        }
                        .mat-menu-trigger.active {
                            background-color: $primary-color;
                        }
                        ul {
                            li {
                                a.static-nav-item {
                                    &.font-weight-bold {
                                        &.text-white {
                                            font-weight: 500 !important;
                                            color: $primary-color !important;
                                        }
                                    }
                                }
                                button.mat-menu-trigger {
                                    &:hover {
                                        background-color: transparent;
                                    }
                                    &.active {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                }
                .shared-header {
                    nav.navbar {
                        ul.navbar-nav {
                            a {
                                img.header-logo {
                                    position: absolute;
                                    top: 5px;
                                    left: -100px;
                                    height: 60px;
                                    width: 510px;
                                }
                            }
                        }
                    }
                }
            }
            .page-body {
                aihub-loader {
                    .loader-overlay {
                        .loader {
                            &:before {
                                background-color: $primary-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
    header {
        .navbar {
            background-color: #f0f0f0;
            .mat-menu-trigger.active {
                background-color: $primary-color;
            }
            ul {
                li {
                    a.static-nav-item {
                        &.font-weight-bold {
                            &.text-white {
                                color: $primary-color !important;
                            }
                        }
                    }
                    button.mat-menu-trigger {
                        &:hover {
                            background-color: $primary-color;
                        }
                        &.active {
                            background-color: $primary-color;
                        }
                    }
                }
            }
        }
    }
    .mat-menu-panel.deloitteai__header--usermenu.mat-menu-below {
        .mat-focus-indicator.text-white.user.mat-menu-item {
            color: $primary-color !important;
            .mat-icon {
                color: $primary-color !important;
            }
        }
    }
    .border-green {
        border-bottom: 0.5rem solid $primary-color;
    }
    .deloitteai__header--usermenu ul {
        background: #f0f0f0;
    }
    .deloitteai__header--usermenu ul .mat-menu-item.bg-green {
        background: #f0f0f0;
        color: $primary-color !important;
        mat-icon {
            color: $primary-color !important;
        }
    }
    .deloitteai__header--usermenu .user {
        background: #f0f0f0;
        &:hover {
            background-color: #dcdcdc;
        }
    }
    .deloitteai__header--usermenu .mat-menu-item {
        color: $primary-color;
        .text-white {
            color: $primary-color !important;
        }
    }
    deloitteai-page-banner {
        background: $primary-color;
    }
    .deloitteai--header__list {
        .title {
            font-size: 1.3rem;
            color: $primary-color;
        }
        .counter {
            color: #f0f0f0;
            background-color: $primary-color;
        }
    }
    // Page Banner
    deloitteai-page {
        deloitteai--onboarding-page {
            deloitteai-page-banner {
                .deloitteai__banner {
                    .deloitteai__banner--inner {
                        .content__body {
                            .content__body--header {
                                h3.header {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // Contact
    deloitteai-page {
        .page-body {
            deloitteai-contact,
            deloitteai--faq-page {
                deloitteai-page-banner {
                    background: $primary-color no-repeat center;
                    .deloitteai__banner--inner {
                        .content__body {
                            .content__body--header {
                                h3 {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    asset-owner-dashboard {
        deloitteai-page-banner {
            .deloitteai__banner--inner {
                .content__body {
                    .content__body--header {
                        h3 {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        deloitteai-page-banner {
            background: $primary-color no-repeat center;
            h3.header {
                color: #ffffff;
                font-family: Arial, Helvetica, Verdana, sans-serif !important;
                font-weight: 400;
            }
            .btn.btn-primary:disabled,
            .btn.btn-primary {
                color: $primary-color;
                background-color: #f0f0f0;
                &:hover {
                    background-color: #f0f0f0;
                    border: 1px solid #dcdcdc;
                    color: $primary-color;
                }
            }
        }
    }
    // Onboarding Page
    deloitteai--onboarding-page {
        .container {
            .row {
                .article {
                    &__header {
                        color: $primary-color;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    // FAQ
    deloitteai--faq-page {
        deloitteai-page-banner {
            .deloitteai__banner {
                .deloitteai__banner--inner {
                    .content__body {
                        .content__body--header {
                            h3.header {
                                color: #f0f0f0;
                            }
                        }
                    }
                }
            }
        }
        .container {
            .section__header {
                color: $primary-color;
                font-weight: 500;
            }
        }
    }
    .btn.btn-primary:disabled,
    .btn.btn-primary {
        color: #f0f0f0;
        background-color: $primary-color;
        &:hover {
            border: 1px solid #dcdcdc;
            color: #f0f0f0;
        }
    }
    .border-green {
        border-bottom: 0.5rem solid $primary-color;
    }
    .bg-black {
        background: $primary-color;
    }
    .catalogue__header {
        background-color: $primary-color !important;
    }
    .accordion-item .accordion-header {
        background-color: #f0f0f0 !important;
        cursor: pointer;
    }
    a {
        color: $primary-color;
    }
    .entitlement_count {
        background-color: $primary-color !important;
    }
    ngu-tile:nth-child(3n+1) .card {
        background: #f0f0f0 !important;
    }
    ngu-tile:nth-child(3n+2) .card {
        background: #f0f0f0 !important;
    }
    ngu-tile:nth-child(3n+3) .card {
        background: #f0f0f0 !important;
    }
    ngu-tile {
        .card {
            .body {
                a {
                    strong {
                        color: $primary-color;
                    }
                }
            }
        }
    }
    deloitteai-banner-link {
        .link-item {
            .link {
                a {
                    color: $primary-color;
                }
            }
        }
    }
    deloitteai-footer {
        background-color: #f0f0f0;
        color: $primary-color;
        a {
            font-weight: 500;
        }
    }
    brain-asset-detail .brain-asset-detail .header {
        background: $primary-color center center no-repeat !important;
        .categories {
            border-top: 0.5rem solid rgb(0, 45, 100) !important;
        }
    }
    .icon-hint {
        border-top: 5px solid $primary-color !important
    }
}